'use client';

import { YouTubeEmbed } from '@next/third-parties/google';
import { useRouter } from 'next/navigation';
import type { ComponentPropsWithoutRef } from 'react';
import useMeasure from 'react-use-measure';
import { Typo } from '~/components/atoms/typography/Typo';
import { Badge } from '~/components/catalyst/badge-catalyst';
import { ShimmerButton } from '~/components/magicui/ShimmerButton';
import { AspectRatio } from '~/components/ui/aspect-ratio';
import { GET_STARTED_URL } from './landing.const';
import { Section } from './Section';

export type HeroProps = {};

export const Hero = (props: HeroProps) => {
  const router = useRouter();
  return (
    <Section
      as="main"
      className="relative flex items-center py-12 md:min-h-[800px] md:py-20 lg:py-32"
    >
      <DotPattern />
      <div className="relative">
        <div className="relative flex flex-col items-center gap-4 text-center">
          <Badge size="lg" color="cyan">
            Turn your passion into profits
          </Badge>
          <Typo variant="h1" className="md:text-5xl lg:text-6xl lg:leading-tight">
            <span className="relative">
              <EmpowerSvg className="absolute inset-x-0 -bottom-3 w-full text-primary" />
              <span className="relative">Empower</span>
            </span>{' '}
            Developers with Your{' '}
            <span className="-mx-2 -my-1 border border-foreground/40 bg-foreground/10 px-2 py-1">
              Knowledge
            </span>
          </Typo>
          <Typo
            variant="large"
            className="font-normal text-muted-foreground md:text-xl lg:text-2xl"
          >
            Create online courses for developers with quizzes, playgrounds, and much
            more!
          </Typo>
          <ShimmerButton
            onClick={() => {
              router.push(GET_STARTED_URL);
            }}
            shimmerSize="0.1em"
            background="hsl(var(--primary))"
            className="text-white hover:bg-primary/80"
          >
            Get started
          </ShimmerButton>
        </div>
        {/* <div className="mx-4 mt-12 md:mx-20 lg:mx-32 lg:mt-16">
          <YouTubeVideo />
        </div> */}
      </div>
    </Section>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HeroYouTubeVideo = () => {
  const [ref, bounds] = useMeasure();

  return (
    <AspectRatio
      ratio={16 / 9}
      className="disable-lite-youtube-max-width flex w-full max-w-full items-center justify-center overflow-hidden border-2 border-accent"
      ref={ref}
    >
      <YouTubeEmbed
        videoid="eKYkDqHohrw"
        width={bounds.width - 1}
        height={bounds.height - 1}
      />
    </AspectRatio>
  );
};

const DotPattern = () => (
  <>
    <div className="bg-dot-pattern absolute inset-0 m-4 opacity-50"></div>
    <div className="absolute inset-0 m-4 bg-gradient-to-b from-transparent via-background/80 to-background"></div>
  </>
);

const EmpowerSvg = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="219"
    height="24"
    viewBox="0 0 219 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 2H218L21 12.5H182.5L48.5 21.5H151"
      stroke="currentColor"
      strokeWidth="4"
    />
  </svg>
);

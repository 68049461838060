import(/* webpackMode: "eager", webpackExports: ["HighlightFeaturesSection"] */ "/vercel/path0/app/(landing)/_components/BentoFeatures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Comparison"] */ "/vercel/path0/app/(landing)/_components/Comparison.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FinishCourses"] */ "/vercel/path0/app/(landing)/_components/FinishCourses.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/app/(landing)/_components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/app/(landing)/_components/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketingFeatures"] */ "/vercel/path0/app/(landing)/_components/MarketingFeatures.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_vx7hixfe76h3eheqblxwih5vtu/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_vx7hixfe76h3eheqblxwih5vtu/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Loader"] */ "/vercel/path0/src/components/atoms/loader/Loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Badge"] */ "/vercel/path0/src/components/catalyst/badge-catalyst.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BorderBeam"] */ "/vercel/path0/src/components/magicui/BorderBeam.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactSupportDialog"] */ "/vercel/path0/src/features/support/ContactSupportDialog.tsx");

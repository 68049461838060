'use client';

import { Box, Boxes, CreditCard, DollarSign, X } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { Typo } from '~/components/atoms/typography/Typo';
import { Badge } from '~/components/catalyst/badge-catalyst';
import { buttonVariants } from '~/components/ui/button';
import MdxPlayground from '~/features/mdx/mdx-component/playground/MdxPlayground';
import { cn } from '~/lib';
import { SiteConfig } from '~/lib/site-config';
import { Section } from './Section';
import { GET_STARTED_URL } from './landing.const';

export function HighlightFeaturesSection() {
  const features = [
    {
      title: 'Create highly interactive courses',
      description:
        'Our application is made to help you create the best possible courses.',
      skeleton: <SkeletonOne />,
      className: 'col-span-1 lg:col-span-4 border-b lg:border-r border-border',
    },
    {
      title: 'Create bundle to increase your revenue',
      description:
        'You can bundle your courses to increase your revenue and get more students.',
      skeleton: <SkeletonTwo />,
      className: 'border-b col-span-1 lg:col-span-2 border-border',
    },
    {
      title: 'Installment Payments',
      description:
        'Allow users to pay for courses in multiple installments, such as 4 payments of 100 euros each.',
      skeleton: <SkeletonThree />,
      className: 'col-span-1 lg:col-span-3 lg:border-r  border-border',
    },
    {
      title: 'Start for free',
      description:
        'Create your first course, and start selling to 5 students for free. No credit card required.',
      skeleton: <SkeletonFour />,
      className: 'col-span-1 lg:col-span-3 border-b lg:border-none',
    },
  ];
  return (
    <Section padding="lg" gap="lg">
      <div className="m-auto flex w-full max-w-xl flex-col items-center gap-2 text-center">
        <Badge size="lg" color="blue">
          What features does {SiteConfig.name} have ?
        </Badge>
        <Typo variant="h1" as="h2">
          We optimize everything for teachers
        </Typo>
        <Typo className="text-lg font-light text-muted-foreground">
          Our objective is to make you so wealthy that you can afford to buy our best
          plans. (but to be wealthy, your users need to be really happy)
        </Typo>
      </div>

      <div className="relative ">
        <div className="mt-12 grid grid-cols-1 border border-border lg:grid-cols-6 xl:border">
          {features.map((feature) => (
            <FeatureCard key={feature.title} className={feature.className}>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
              <div className="size-full">{feature.skeleton}</div>
            </FeatureCard>
          ))}
        </div>
      </div>
    </Section>
  );
}

const FeatureCard = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn(`p-4 sm:p-8 relative overflow-hidden`, className)}>
      {children}
    </div>
  );
};

const FeatureTitle = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Typo variant="h3" as="p">
      {children}
    </Typo>
  );
};

const FeatureDescription = ({ children }: { children?: React.ReactNode }) => {
  return <Typo className="text-muted-foreground">{children}</Typo>;
};

const SkeletonOne = () => {
  return (
    <div className="mt-4">
      {/* @ts-expect-error - Zod is not happy */}
      <MdxPlayground
        files={{
          'App.js': `import { useState } from 'react';

export default function App() {
  const [count, setCount] = useState(0);

  return (
    <div className="p-2 bg-white rounded-lg shadow-lg m-2">
      <h2 className="text-lg font-bold mb-2">Counter</h2>
      <p className="text-gray-700 mb-2">
        Editable code block on the landing page.
      </p>
      <div className="flex items-center">
        <button
          className="px-2 py-1 bg-blue-500 text-white rounded mr-2"
          onClick={() => setCount(count - 1)}
        >
          -
        </button>
        <span className="text-lg">{count}</span>
        <button
          className="px-2 py-1 bg-blue-500 text-white rounded ml-2"
          onClick={() => setCount(count + 1)}
        >
          +
        </button>
      </div>
    </div>
  );
};`,
        }}
        tailwind
        size="extraSmall"
        template="react"
        title="Playground"
        defaultTab="console"
      />
    </div>
  );
};

const SkeletonThree = () => {
  return (
    <div className="flex w-full items-end justify-end">
      <Image
        src="/images/multi-prices-checkout.png"
        alt="multi-prices"
        width={200}
        height={200}
      />
    </div>
  );
};

const SkeletonTwo = () => {
  return (
    <div className="flex flex-col items-center gap-2 p-4">
      <div className="flex items-center gap-1">
        <div className="flex flex-col items-center gap-0.5 bg-accent p-2">
          <Typo variant="muted">JSPRO</Typo>
          <Box size={24} />
        </div>
        <Typo variant="large">+</Typo>
        <div className="flex flex-col items-center gap-0.5 bg-accent p-2">
          <Typo variant="muted">TSPRO</Typo>
          <Box size={24} />
        </div>
        <Typo variant="large">+</Typo>
        <div className="flex flex-col items-center gap-0.5 bg-accent p-2">
          <Typo variant="muted">ReactPRO</Typo>
          <Box size={24} />
        </div>
      </div>
      <Typo variant="large">=</Typo>
      <div className="flex flex-col items-center gap-0.5 bg-accent p-2">
        <Typo variant="muted">WEB PRO BUNDLE</Typo>
        <Boxes size={24} />
      </div>
    </div>
  );
};

const SkeletonFour = () => {
  return (
    <div className="mt-8  flex w-full flex-col items-center gap-6">
      <div className="flex items-center justify-center gap-4">
        <div className="relative">
          <CreditCard size={42} />
          <X size={64} className="absolute -inset-2 text-red-500" />
        </div>
        <Typo variant="large">+</Typo>
        <div className="relative">
          <DollarSign size={42} className="text-green-500" />
        </div>
        <Typo variant="large">=</Typo>
        <div className="relative">
          <Boxes size={42} />
          <X size={64} className="absolute -inset-2 text-red-500" />
        </div>
      </div>
      <Link
        href={GET_STARTED_URL}
        className={buttonVariants({ variant: 'outline' })}
      >
        Get started
      </Link>
    </div>
  );
};
